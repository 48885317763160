<template>
  <article class="uploaded-by">
    <tt-avatar v-if="isCreatedByUser"
               :own="true"
               class="uploaded-by__avatar" />
    <figure v-else
            class="uploaded-by__admin"></figure>
    <p class="uploaded-by__author"> {{ author }}</p>
  </article>
</template>

<script>
  export default {
    props: {
      creator: {
        type: String,
        default: ''
      }
    },
    computed: {
      isCreatedByUser() { return this.creator === 'user'; },
      author() { return this.isCreatedByUser ? this.$gettext('You') : this.$getString('domainData', 'platformName'); }
    }
  };
</script>
<style scoped>
.uploaded-by {
  display: flex;
  align-items: center;
}

.uploaded-by__avatar,
.uploaded-by__admin {
  width: 20px;
  height: 20px;
}

.uploaded-by__admin {
  background-image: var(--image-avatar-logo);
  background-repeat: no-repeat;
}

.uploaded-by__author {
  margin-left: 8px;
}
</style>
