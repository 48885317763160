<template>
  <article class="file">
    <figure class="file__icon"
            :class="[`file__icon--${fileType}`]">
    </figure>
    <p class="file__name">{{ fileName }}</p>
  </article>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      name: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    },
    computed: {
      fileType() { return helpers.file.fileType(this.type); },
      fileName() { return this.name; },
    }
  };
</script>

<style scoped>
.file {
  display: flex;
  align-items: center;
}

.file__icon {
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background: var(--cool-gray-50);
  background-image: url(~@assets/imgs/file_types/document.svg);
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.file__icon--document {
  background-image: url(~@assets/imgs/file_types/document.svg);
}

.file__icon--audio {
  background-image: url(~@assets/imgs/file_types/audio.svg);
}

.file__icon--image {
  background-image: url(~@assets/imgs/file_types/image.svg);
}

.file__name {
  margin-right: 4px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  word-break: break-all;
}
</style>
