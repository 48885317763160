(<template>
  <section :class="{'in-progress': progress}"
           class="page-data-table is-progress-bar">
    <div class="page-data-table__row page-data-table__row--header">
      <div v-for="(cell, index) in cells"
           :key="index"
           class="page-data-table__cell"
           :class="[getHeaderTypeClass(cell)]">{{ cell.name }}</div>
      <div v-if="isDownloadable"
           class="page-data-table__cell page-data-table__cell--download-right-side"></div>
      <div v-if="isEditable"
           class="page-data-table__cell page-data-table__cell--edit"></div>
      <div v-if="isDeletable"
           class="page-data-table__cell page-data-table__cell--delete"></div>
    </div>

    <component :is="rowLinks ? 'router-link' : 'div'"
               v-for="(obj, rowIndex) in data"
               :key="rowIndex"
               :to="obj.rowLink ? obj.rowLink : ''"
               :class="{'page-data-table__row-wrapper--editable': isEditable, 'is-stripped': stripped}"
               class="page-data-table__row-wrapper"
               @click="$emit('rowclicked', obj)">
      <div class="page-data-table__row">
        <div v-for="(cell, cellIndex) in cells"
             :key="cellIndex"
             :class="[getCellTypeClass(cell), cell.classList]"
             class="page-data-table__cell-wrapper">
          <div class="page-data-table__cell-name">{{ cell.name }}</div>
          <div v-if="cell.type == 'download'"
               class="page-data-table__cell">
            <button v-if="getData(obj, cell) && obj.downloadLink"
                    class="download-btn sk-btn sk-btn--downloading"
                    @click="$emit('download', obj.id)"></button>
          </div>
          <file-cell v-else-if="cell.type === 'file'"
                     :name="obj[cell.dataField]"
                     :type="obj.contentType" />
          <uploaded-by v-else-if="cell.type === 'uploadedBy'"
                       :creator="obj[cell.dataField]" />
          <div v-else
               :class="[getStatusClass(obj, cell)]"
               class="page-data-table__cell"
               v-html="getData(obj, cell, rowIndex)"></div>
        </div>
      </div>
      <div v-if="isDownloadable"
           class="page-data-table__cell page-data-table__cell--download-right-side">
        <button :class="[downloadOpts.classList, {'page-data-table__download-btn--icon': !downloadOpts.classList}]"
                class="page-data-table__download-btn"
                @click="$emit('downloaditem', obj)"
                v-html="downloadBtnText"></button>
      </div>
      <div v-if="isEditable"
           class="page-data-table__cell page-data-table__cell--edit">
        <button :class="editOpts.classList"
                class="sk-btn sk-link sk-btn--like-link page-data-table__edit-btn"
                @click="$emit('edititem', obj)"
                v-html="editBtnText"></button>
      </div>
      <div v-if="isDeletable"
           class="page-data-table__cell page-data-table__cell--delete">
        <button :class="[deleteOpts.classList, {'page-data-table__delete-btn--icon': !deleteOpts.classList}]"
                class="page-data-table__delete-btn"
                @click="$emit('deleteitem', obj.id)"
                v-html="deleteBtnText"></button>
      </div>
    </component>
  </section>
</template>)

<script>
  import FileCell from '@/components/shared_components/page_data/cells/FileCell';
  import UploadedByCell from '@/components/shared_components/page_data/cells/UploadedByCell';

  export default {
    components: {
      'file-cell': FileCell,
      'uploaded-by': UploadedByCell
    },
    props: {
      data: {
        type: Array,
        required: true
      },
      // cells: [{
      //   name: '',
      //   dataField: '',
      //   type: '', // date | text | status | ...
      //   ...<extraOptions>
      // }, ...]
      //
      //
      // Cell types:
      // - date       - options: dateFormat: ''
      // - itemNumber - options: fieldTemplate: $gettext('%{id} %{index}')
      // - status     - options: statusList: [{id: '', text: ''}, ...]
      // - download   - downloadItem link
      // - custom     - options: customFieldFunc: (dataField, dataRow) => { dataField }
      // - text       - default. field data
      cells: {
        type: Array,
        required: true
      },
      progress: Boolean,
      isEditable: Boolean,
      isDeletable: Boolean,
      isDownloadable: Boolean,
      // {classList: ['class-name'], btnText: ''}
      deleteBtnOpts: {
        type: Object,
        default() {
          return {};
        }
      },
      // {classList: ['class-name'], btnText: ''}
      editBtnOpts: {
        type: Object,
        default() {
          return {};
        }
      },
      downloadBtnOpts: {
        type: Object,
        default() {
          return {};
        }
      },
      // defines if the table rows will be white or stripped
      stripped: {
        type: Boolean,
        default: true
      },
      // use when we need clickable rows
      rowLinks: {
        type: Boolean,
        default: false
      },
      cellHeaderBreak: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      downloadOpts() { return this.downloadBtnOpts || {}; },
      editOpts() { return this.editBtnOpts || {}; },
      deleteOpts() { return this.deleteBtnOpts || {}; },
      editBtnText() { return this.editOpts.btnText !== undefined ? this.editOpts.btnText : this.$gettext('Edit'); },
      deleteBtnText() { return this.deleteOpts.btnText; },
      downloadBtnText() { return this.downloadOpts.btnText; },
    },
    methods: {
      getData(obj, cell, rowIndex) {
        if (obj[cell.dataField]) {
          switch (cell.type) {
            case 'date':
              return this.getDate(obj[cell.dataField], cell.dateFormat);
            case 'itemNumber':
              return this.getItemNumber(obj[cell.dataField], rowIndex, cell.fieldTemplate);
            case 'status':
              return this.getStatusText(obj[cell.dataField], cell.statusList);
            case 'custom':
              return cell.customFieldFunc ? cell.customFieldFunc(obj[cell.dataField], obj) : obj[cell.dataField];
            case 'combined':
              return `${obj[cell.dataField]} ${obj[cell.dataFieldExtended]}`;
            default:
              return obj[cell.dataField];
          }
        }
        return '--';
      },
      getDate(date, dateFormat) {
        const format = dateFormat || 'DD/MM/YYYY';
        return this.$moment(date).format(format);
      },
      getItemNumber(itemNumber, itemIndex, fieldTemplate) {
        const template = fieldTemplate || this.$gettext('#DOC-%{id}');
        return this.$gettextInterpolate(template, {
          id: itemNumber,
          index: itemIndex + 1
        });
      },
      getStatusText(status, statusList) {
        const statusObject = statusList.find((item) => item.id == status) || {};
        return statusObject.text || '';
      },
      getHeaderTypeClass(cell) {
        let headerClass = cell.type ? `page-data-table__cell--${cell.type}` : '';

        if (this.cellHeaderBreak) {
          headerClass += ' cell-header-break-words';
        }

        return headerClass;
      },
      getCellTypeClass(cell) {
        return cell.type ? `page-data-table__cell-wrapper--${cell.type}` : '';
      },
      getStatusClass(obj, cell) {
        return cell.type === 'status' ? `page-data-table__cell--${obj[cell.dataField]}` : '';
      }
    }
  };
</script>

<style scoped>
  .page-data-table {
    width: 100%;
    border-spacing: 0;
  }

  .page-data-table__row-wrapper {
    display: flex;
    padding: 0 20px;
    border-top: 1px solid #e9eaef;
    background-color: #fff;
  }

  .page-data-table__row {
    display: flex;
    width: 100%;
    background-color: #fff;
  }

  .page-data-table__row-wrapper:nth-child(even).is-stripped,
  .page-data-table__row-wrapper:nth-child(even).is-stripped .page-data-table__row {
    background-color: #f4f5f7;
  }

  .page-data-table__row--header {
    display: flex;
    padding: 0 20px;
    font-weight: bold;
  }

  .page-data-table__cell {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 5px;
  }

  .page-data-table__cell--edit,
  .page-data-table__cell--download,
  .page-data-table__cell--download-right-side,
  .page-data-table__cell--delete {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 40px;
  }

  .page-data-table__cell--download {
    max-width: none;
  }

  .page-data-table__cell--edit {
    font-size: 12px;
  }

  .page-data-table__row--header .page-data-table__cell--edit,
  .page-data-table__row--header .page-data-table__cell--delete {
    border: none;
  }

  .page-data-table__cell-wrapper {
    display: flex;
    width: 100%;
  }

  .page-data-table__cell-wrapper--file {
    margin: 10px 0;
  }

  .page-data-table__cell--file,
  .page-data-table__cell-wrapper--file {
    width: 300%;
  }

  .page-data-table__cell-name {
    display: none;
  }

  .page-data-table__delete-btn,
  .page-data-table__download-btn {
    display: block;
    width: 35px;
    height: 35px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .page-data-table__delete-btn--icon {
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-size: 10px auto;
  }

  .page-data-table__download-btn--icon {
    background-image: url(~@assets/imgs/salita/download_orange_icon.svg);
    background-size: 15px auto;
  }

  .download-btn:focus {
    outline: var(--default-button-focus-outline);
  }

  .cell-header-break-words {
    word-break: break-word;
  }

  @media (max-width: 1024px) {
    .page-data-table__row--header {
      display: none;
    }

    .page-data-table__row {
      position: relative;
      flex-wrap: wrap;
      width: 100%;
      padding: 10px 0;
    }

    .page-data-table__row-wrapper {
      display: flex;
      padding: 0 15px;
      background-color: #fff;
    }

    .page-data-table__row-wrapper--editable {
      flex-wrap: wrap;
      padding-bottom: 10px;
    }

    .page-data-table__cell-wrapper {
      align-items: center;
    }

    .page-data-table__cell {
      width: 75%;
      padding: 0 0 0 5px;
    }

    .page-data-table__cell-wrapper--download .page-data-table__cell {
      position: absolute;
      top: 50%;
      right: 0;
      width: auto;
      transform: translate(0, -50%);
    }

    .page-data-table__cell-wrapper--download .page-data-table__cell-name {
      display: none;
    }

    .page-data-table__cell-name {
      display: flex;
      width: 25%;
      margin-right: 10px;
      font-weight: bold;
    }

    .page-data-table__cell--edit,
    .page-data-table__cell--delete {
      max-width: 30px;
    }

    .page-data-table__row-wrapper--editable .page-data-table__cell--edit {
      justify-content: flex-start;
      margin-right: auto;
      padding: 0;
    }

    .page-data-table__row-wrapper--editable .page-data-table__delete-btn {
      justify-content: flex-end;
      background-position: 100% 50%;
    }
  }
</style>
