// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/file_types/document.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/file_types/audio.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/file_types/image.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.file[data-v-56a66843] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.file__icon[data-v-56a66843] {\n  min-width: 40px;\n  height: 40px;\n  border-radius: 8px;\n  background: var(--cool-gray-50);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: 20px;\n  background-repeat: no-repeat;\n}\n.file__icon--document[data-v-56a66843] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.file__icon--audio[data-v-56a66843] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.file__icon--image[data-v-56a66843] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.file__name[data-v-56a66843] {\n  margin-right: 4px;\n  margin-left: 10px;\n  font-weight: 700;\n  font-size: 14px;\n  word-break: break-all;\n}\n", ""]);
// Exports
module.exports = exports;
